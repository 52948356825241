.App {
  text-align: center;
  font-family: "Trip Sans";
}
h1 {
  margin: 0px;
}
h2 {
  margin: 0px;
}
h3 {
  margin: 0px;
}
h4 {
  margin: 0px;
}
h6 {
  margin: 0px;
  font-family: "Trip Sans";
}
h5 {
  margin: 0px;
  font-family: "Trip Sans";
}
p {
  margin: 0px;
  font-family: "Trip Sans";
}

@font-face {
  font-family: "Trip Sans";
  src: local("Trip Sans Regular"), local("Trip-Sans-Regular"),
    url("././assets/fonts/TripSans-Regular.woff2") format("woff2"),
    url("././assets/fonts/TripSans-Regular.woff") format("woff"),
    url("././assets/fonts/TripSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Trip Sans";
  src: local("Trip Sans Medium"), local("Trip-Sans-Medium"),
    url("././assets//fonts/TripSans-Medium.woff2") format("woff2"),
    url("././assets//fonts/TripSans-Medium.woff") format("woff"),
    url("././assets//fonts/TripSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Trip Sans";
  src: local("Trip Sans Bold"), local("Trip-Sans-Bold"),
    url("././assets//fonts/TripSans-Bold.woff2") format("woff2"),
    url("././assets//fonts/TripSans-Bold.woff") format("woff"),
    url("././assets//fonts/TripSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.list-styles {
  font-size: 14px !important;
  color: black !important;
  line-height: 29px;
  font-weight: 400;
}
.auto-margin {
  margin: auto;
}
/* button{
  padding-inline: 20px !important;
} */
.font-size-14 {
  font-size: 14px;
}
.flex {
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.align-item-start {
  align-items: flex-start;
}
.align-item-center {
  align-items: center;
}
.gap-2 {
  gap: 2px;
}
.gap-8 {
  gap: 8px;
}
.gap-16 {
  gap: 16px;
}
.gap-24 {
  gap: 24px;
}
.icon-black {
  color: #000;
}
.height-6 {
  height: 6px;
}
.icon-white {
  color: white;
}
.icon-green {
  color: green;
}
.icon-yellow {
  color: #f3a218;
}
.icon-blue {
  color: #003e9c;
}
.light-blue {
  color: #0065ff;
}
.space-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: flex-end;
}
.space-left {
  justify-content: left;
}
.max-width-962 {
  max-width: 962px;
}
.margin-top-54 {
  margin-top: 54px;
}
.margin-top-40 {
  margin-top: 40px;
}
.margin-top-25 {
  margin-top: 25px;
}
.margin-top-32 {
  margin-top: 32px;
}
.margin-top-16 {
  margin-top: 16px;
}
.margin-bottom-200 {
  margin-bottom: 100px;
}
.margin-top-10 {
  margin-top: 10px;
}
.margin-top-20 {
  margin-top: 20px;
}
.margin-inline-24 {
  margin-inline: 24px;
}
.little-margin-top {
  margin-top: 3px;
}
.margin-right {
  margin-right: 18px;
}

.App-link {
  color: #61dafb;
}

.text-color {
  color: rgb(11, 11, 11) !important;
}
.release_div {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.rendered {
  max-width: 1340px;
  padding-bottom: 1.5rem;
}
.rendered_div {
  width: inherit;
  padding: 0 2.5rem;
  /* width: 80vw; */
}
.rendered_div img {
  width: 650px;
  height: 400px;
  margin: 16px 0;
}
.rendered_div .wp-block-image {
  display: flex;
  justify-content: center;
  margin: 16px 0;
  /* width: 100%; */
}
.rendered_div a {
  color: #0065ff;
  font-weight: 500;
}
.rendered_div h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 28.8px;
  color: #0065ff;
  padding-block: 23px;
  padding-left: 55px;
  background-color: #e6f0ff;
  border-radius: 4px;
  margin-block: 64px 32px;
}

.rendered_div p {
  margin: 14px 0;
  font-size: 1.1rem;
  width: 100%;
  line-height: 30px;
}

.rendered_div ul {
  list-style-type: disc;
  margin-left: 20px;
  padding-left: 20px;
}

.rendered_div li {
  margin-bottom: 10px;
}

.wp-block-heading {
  font-size: 24px;
  font-weight: bold;
  line-height:36px;
}

.has-large-font-size {
  font-size: 24px!important;
  font-weight: bold;
  line-height:30px!important;
}

.wp-block-quote {
  display: flex;
  justify-content: center;
  font-style: italic;
  font-weight: 600;
  width: inherit;
}

.wp-block-quote p {
  width: fit-content;
  font-size: 1.3rem;
}

.custom-pagination .Mui-selected {
  background-color: #0065ff !important;
  color: #fff !important;
}
.thumbnail {
  width: 100%;
  margin-top: 5rem;
  margin-bottom: 2rem;
  height: 350px;
  overflow: hidden
}

.thumbnail img {
  width: 100%;
  height: 350px;
}

.back-button {
  width: fit-content;

}

.back-button span{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem;
  border: #0065ff 1px solid;
  border-radius: 50%;
  width: fit-content;
  color: #0065ff;
 
}
 

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* @media (min-width: 1366px) {
  .container {
    width: 1200px;
    margin: auto;
  }
} */
/* @media (max-width: 850px) {
  .blogimg{
      display: block !important;
  }
  .blogimg img{
    display: block !important;
}
} */
@media (max-width: 550px) {
  h1 {
    font-size: 24px !important;
  }
  h2 {
    font-size: 20px !important;
    line-height: 24px !important;
  }
  h3 {
    font-size: 20px !important;
    line-height: 24px;
  }
  p {
    font-size: 16px !important;
  }
  .rendered_div {
    padding: 0 1.5rem;
  }
  .release_div h2 {
    margin-block: 49px 32px;
    border-radius: 0px;
    padding-left: 0px;
    text-align: center;
    margin-inline: 0px;
  }
  .thumbnail img {
    width: 100%;
    height: 16rem;
  }
}
@media (max-width: 1106px) {
  .flex-wrap {
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  .flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
}

@media screen and (max-width: 800px) {
  .rendered_div img {
    width: 100%;
    height: auto;
    transform: scale(1.1);
  }
  .rendered_div .wp-block-image {
    width: inherit;
  }
}


@media (max-width: 984px) {
  .thumbnail{
    margin-top: 0;
  }
}