hr {
    border: 1px solid #F5F6F7;
    /* width: 70%; */
    margin-block: 16px;
}

.overalldropdown {
    max-width: 986px;
    padding: 20px 40px;
}
.solutionheader h6{
    font-weight: 700;
    font-size: 16px;
    line-height: 29px;
    letter-spacing: -1%;
}

@media (max-width: 430px) {
    .logo {
        filter: brightness(0) invert(1) saturate(0);
    }
    .menuicon {
        filter: invert(100%);
    }
}
@media (min-width: 983px) {
    .headeroverall {
        background-color: #FFF;
        width: 100%;
        margin: auto;
        position: fixed;
        z-index: 999;
    }
}
@media (max-width: 1366px) {
    .container {
      max-width: 1200px;
      margin: auto;
    }
}

@media (min-width: 1366px) {
    .container {
      max-width: 1300px;
      margin: auto;
    }
}
