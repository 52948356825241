.pricemain {
    background-color: #E6F0FF;
    border-radius: 15.42px;
    padding: 15.42px;
    max-width: 422.14px;
}
.pricecont {
    background: #FFF;
    border-radius: 15.42px;
    padding: 26.02px 27.95px 26.02px 27.95px;
}
.pricecont h3{
    font-weight: 700;
    font-size: 32px;
    line-height: 38.4px;
    margin-bottom: 23px;
}
.pricecont h5 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 23px;
}
.pricecont h6 {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 23px;
}
.subdesc p {
    font-weight: 400;
    font-size: 16px;
    line-height: 22.4px;
}
.featuredesc {
    margin-bottom: 15.42px;
}