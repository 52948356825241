.forfeatures {
    position: relative;
    display: flex;
    padding-top: 64px;
    padding-bottom: 64px;
    padding-left: 54px;
    border-radius: 16px;
    margin-bottom: 40px;
}
.featureleft,
.featureright {
    flex: 1;
}
.featureleft {
    padding-bottom: 65px;
}
.featureright {
    position: relative;
}
.featureleftheader {
    max-width: 508px;
}
.featureright img {
    max-width: 100%;
    min-width: 100%;
    bottom: 0;
    border-radius: 0px 0px 16px 0px;
}
.featureleft h6 {
    font-weight: 700;
    font-size: 16px;
    line-height: 22.4px;
    letter-spacing: -1%;
    margin-bottom: 16px;
}
.featureleft h5 {
    font-weight: 700;
    font-size: 24px;
    line-height: 33.6px;
    letter-spacing: -1%;
    margin-bottom: 16px;
}
.featureleft p {
    font-weight: 400;
    font-size: 16px;
    line-height: 22.4px;
    letter-spacing: -1%;
}
.featuredesc {
    margin-bottom: 40px;
}

@media screen and (max-width: 1024px) {
    .forfeatures{
        display: grid;
        grid-template-columns: 1fr;
        padding: 2rem;
    }

    .featureright{
        width: 100%;
        padding-right: 2rem;
    }
    .featureleft h5 {
        font-weight: 700;
        font-size: 24px;
        line-height: normal;
        margin-bottom: 0;
    }
}
@media screen and (max-width: 550px) {
    .forfeatures{
        display: grid;
        grid-template-columns: 1fr;
        padding: 2rem 1rem;
    }

    .featureright{
        width: 100%;
        padding-right: 2rem;
    }
}