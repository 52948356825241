.containerbcg {
    background-color: #f4f2f2;
}
.contmargin {
    margin-inline: 24px;
    color: #FFF;
    padding-top: 2rem;
}
.releaseheader {
    margin-top: 5rem;
    border-radius: 20px;
    background-color: #04326B;
    background-image: url('../../assets/images/underlayfooter.png');
    background-blend-mode: overlay;
    background-size: cover;
    overflow: hidden;
    padding-block: 35px;
    position: relative;
    text-align: center;
}
.releaseheader h1 {
    font-weight: 500;
    font-size: 46px;
    line-height: 67.2px;
    max-width: 691px;
    margin: auto;
    margin-bottom: 14px;
}
.releaseheader p {
    font-weight: 400;
    font-size: 16px;
    line-height: 33.6px;
    letter-spacing: -1%;
    max-width: 621px;
    margin: auto;
}
.releaseupdate {
    margin: auto;
}
.releaseupdate p {
    font-weight: 400;
    font-size: 16px;
    line-height: 28.8px;
    letter-spacing: -1%;
}
.releaseupdate a {
    font-weight: 400;
    font-size: 14px;
    line-height: 28.8px;
    color: #FFF;
    letter-spacing: -1%;
}

.pagination {
    display: flex;
    justify-content: center;
    padding: 20px 0;
  }
  

/* FOR RELEASE UPDATE OVERVIEW REUSABLE COMPONENT */
.updatemaincont {
    margin-top: 76px;
}

.updatediv{
    display: flex;
    align-items: center;
    justify-content: center;
}

.forupdateimg img {
    width: 100%;
    height: 8rem;
}
.releaseupdateinfo h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 33.6px;
    color: black;
}
.releaseupdateinfo p {
    font-weight: 400;
    line-height: 28px;
    font-size: 14px;
    color: black;
}
.releaseupdateinfo a {
    font-weight: 500;
    line-height: 28px;
    font-size: 14px;
    color: #0065FF;
}
hr {
    border: 1px solid #DFE2E7;
    margin-top: 33px;
    width: 80%;

}

@media (max-width: 984px) {
.releaseheader{
    margin-top: 0 !important;
}
}

@media (max-width: 600px) {
    .contmargin {
        padding-block: 50px;
    }
    .updatemaincont {
        margin-top: 30px;
    }
    .releaseupdate a {
        font-size: 14.61px;
        line-height: 19.93px;
        font-weight: 400;
    }
    .releaseheader h1 {
        font-size: 24px;
        line-height: normal;
    }
    .releaseheader {
        padding-inline: 20px;
    }
    .releaseheader p {
        font-size: 14px !important;
        padding-bottom: 0.8rem;
        line-height: normal;
    }
    .releaseupdateinfo a {
        font-size: 14px;
        line-height: 19.93px;
    }
    /* FOR RELEASE UPDATE REUSABLE */
    .releaseupdateinfo h4 {
        font-size: 16px;
        line-height: 28px;
    }
    .releaseupdateinfo p {
        margin-block: 8px 16px;
    }
}
@media (max-width: 850px) {
    .updateleft {
        display: none;
    }
    .updateright {
        flex: 2;
    }
    .forupdateimg img {
        display: none;
    }
    .blogimg{
        display: block !important;
    }
}
@media (min-width: 1366px) {
    .container {
      width: 1300px;
      margin: auto;
    }
}