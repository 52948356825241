.herosectionbcg {
    background-image: url(../../assets/images/pricinghero.jpg);
    /* background-position: center; */
    background-repeat: no-repeat;
    background-size: cover;
}
.pricingcontainer {
    background-color: #F9FCFF;
    padding-block: 54px;
}
.contmargin {
    margin-inline: 24px;
}
.priceheader {
    margin: auto;
    padding-block: 214px 250px;
    max-width: 989px;
    text-align: center;
}
.priceheader h1 {
    font-weight: 700;
    font-size: 54px;
    line-height: 64.8px;
    margin-bottom: 32px;
}
.priceheader h4 {
    font-weight: 400;
    font-size: 24px;
    line-height: 33.6px;
    max-width: 621px;
    margin: auto;
    margin-bottom: 32px;
}
.getstartedbtn {
    max-width: 257px;
    margin: auto;
}
/* PriceCard */
.pricingheader {
    max-width: 777px;
    margin: auto;
    text-align: center;
    margin-bottom: 32px;
}
.pricingheader h3{
    font-weight: 700;
    font-size: 32px;
    line-height: 44.8px;
    letter-spacing: -1%;
}
.pricingheader p{
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -1%;
    margin-top: 8px;
}
/* COMPARE PLANS */
.planandfeatures {
    margin-bottom: 98px;
}
.compareplansheader {
    max-width: 781px;
    margin: auto;
    text-align: center;
    margin-block: 70px 48px;
}
.compareplansheader h3 {
    font-size: 32px;
    line-height: 44.8px;
    font-weight: 700;
}
.compareplansheader p{
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -1%;
}
.tabledata {
    font-size: 18px;
    line-height: 18px;
    font-weight: 400;
}
@media (min-width: 1366px) {
    .container {
      width: 1300px;
      margin: auto;
    }
}