.btn {
    background: #FFFFFF;
    padding-block: 20px;
    width: 257px;
    border-radius: 16px;
    border: 1px solid #0065FF;
    font-size: 14px;
    cursor: pointer;

    font-size: 20px;
    color: #0065FF;
    font-weight: 500;
    line-height: 28px;
    text-align: center;
}
.btnsm {
    background: #0065FF;
    padding-block: 12px;
    width: 170px;
    border-radius: 8px;
    font-size: 14px;
    cursor: pointer;
    margin-left: auto;
    margin-block: 23px;
    color: #FFFFFF;
    font-weight: 500;
    /* line-height: 28px; */
    text-align: center;
}
.btnlg {
    background: #0065FF;
    padding-block: 13.4px;
    width: 342px;
    border-radius: 8px;
    cursor: pointer;
    margin: auto;
    margin-block: 16px 0px;

    font-size: 14x;
    color: #FFFFFF;
    font-weight: 700;
    line-height: 16.44px;
    text-align: center;
}
.btnmd {
    cursor: pointer;
    background: #0065FF;
    /* padding-inline: 39px; */
    padding-block: 16px;
    border-radius: 8px;
    margin: auto;
    max-width: 318px;

    font-size: 14px;
    line-height: 28px;
    font-weight: 700;
    color: #FFFFFF;
}
.btnarr {
    cursor: pointer;
    background: #0065FF;
    /* padding-block: 10px 20px; */
    height: 43px;
    width: 180px;
    border-radius: 16px;
    font-size: 16px;
    display: flex;
    align-items: center !important;
    text-decoration: none;
    font-size: 16px;
    color: #FFFFFF;
    font-weight: 500;
    line-height: 28px;
    text-align: center;
}
@media (max-width: 430px) {
    .btn,
    .btnarr {
        width: 100%;
        padding: 0 !important;
    }
}