.footerfirstbcg {
    background-color: #E6F0FF;
    padding: 24px;
}
.footersecondbcg {
    border-radius: 20px;
    background-color: #04326B;
    background-image: url('../../assets/images/underlayfooter.png');
    background-blend-mode: overlay;
    background-size: cover;
    overflow: hidden;
    position: relative;
}

.contmargin {
    margin-inline: 24px;
    padding-block: 98px 44px;
    color: #FFF;
}
.firstrow .links,
.secondrow .links {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.firstrow a,
.secondrow a {
    font-weight: 500;
    font-size: 16px;
    line-height: 26.1px;
    text-decoration: none;
    color: #FFF;
    margin-bottom: 10px;
}
.firstrow a:first-of-type,
.secondrow a:first-of-type {
    font-size: 18px;
}

.firstrow {
    margin-bottom: 90px;
}
.firstrow p {
    font-weight: 500;
    font-size: 16px;
    line-height: 23.2px;
    margin-bottom: 8px;
}
.contmargin hr {
    border: 1px solid #345D8E;
    margin-block: 16px;
}
.secondrow {
    margin-block: 30px 100px;
}
.mobilefooter {
    display: none;
}
.newsletter{
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 100%;
    gap: 1rem;
    margin-bottom: 2rem;
}
.newsletter .logo{
    padding-bottom: 2rem;

    margin-bottom: 2rem;
}
.newsletter p{
    text-align: center;
    margin-bottom: 0.5rem;
}
.newsletter .email{
    display: flex;
    align-items: center;
    gap: 1rem
    ;
    flex-direction: row;
    min-width: 100%;
    margin-top: 2rem;
    padding-top: 2rem;
}
.newsletter input{
    background: white;
    padding: 0.5rem 1rem;
    width: 260px !important;
    border: 0;
    border-radius: 100px;
}
.newsletter button{
    margin-left: 1rem;
    background: #2B7FFF;
    color: white;
    padding: 0.5rem 1rem;
    border: 0;
    border-radius: 100px;
}
@media screen and (max-width: 550px) {
    .newsletter input{
        width: 100px !important;
    }
    .newsletter .email{
        gap: 8px !important;
    }
    .newsletter button{
        margin-left: 8px;
        font-size: 0.8rem;
        padding: 0.5rem;
    }
}

@media (max-width: 430px) {
    .contmargin  {
        padding-top: 3rem;
        /* padding-block: 40px 300px; */
    }
    .mobilefooter {
        display: block;
        color: #FFF;
    }
    .footerimg {
        margin-bottom: 10px;
    }
    .firstrow,
    .secondrow,
    .thirdrow,
    .contmargin hr {
        display: none;
    }
    .onlinestores {
        margin-block: 16px;
        display: flex;
        flex-direction: column;
        align-items: left;
        gap: 12px;
    }
    .onlinestores img {
        width: 50%;
    }
    small {
        font-weight: 400;
        font-size: 14px;
        line-height: 20.3px;
    }
    .footerlinks {
        margin-top: 32px;
    }
    .footerlinks a {
        display: block;
        font-weight: 500;
        font-size: 16px;
        line-height: 23.2px;
        margin-bottom: 8px;
        cursor: pointer;
        color: #FFF;
        text-decoration: none;
    }
    
}
@media (min-width: 1366px) {
    .container {
      width: 1300px;
      margin: auto;
    }
}