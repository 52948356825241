.containerbcg{
    background-color: #F2F2F2;
}
.policiesbcg {
    border-radius: 0px 0px 20px 20px;
    background-color: #04326B;
    background-image: url('../../assets/images/underlayfooter.png');
    background-blend-mode: overlay;
    background-size: cover;
    overflow: hidden;
    padding-block: 86px;
    position: relative;
    text-align: center;
}
.policiesbcg h1 {
    font-weight: 500;
    line-height: 67.2px;
    font-size: 56px;
    margin-bottom: 5px;
}
.policiesbcg p {
    font-weight: 400;
    font-size: 20px;
    line-height: 33.6px;
}
.contmargin {
    margin-inline: 24px;
    padding-block: 130px 44px;
    color: #FFF;
}
.policymaincont {
    margin-top: 93px;
}
.policycontent {
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #000;
}
.policyrightitem a {
    font-weight: 500;
    line-height: 24px;
    font-size: 16px;
    color: #0065FF;
    margin-bottom: 16px;
}
.textsubheader {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -1%;
    color: #000;
    margin-top: 24px;
}
.css-lfwcke-MuiTabs-flexContainer {
    align-items: flex-start !important;
}
.itemleft{
    padding: 28px;
    background-color: #FFF;
    height: 600px;
    border-radius: 8px;
    margin-right: 10px;
}
.itemleft a,
.itemleft p {
    color: #000;
    text-decoration: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 32px;
    display: block;
}
.itemleft a:active{
    color: #0065FF;
}
.itemright {
    background-color: #FFF;
    padding: 28px;
    border-radius: 8px;
}
.itemright h5 {
    color: #000;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
}
/* .circleforparagraph img {
    height: 6px;
} */
@media (min-width: 1366px) {
    .container {
      width: 1300px;
      margin: auto;
    }
}