.containerbcg {
    background-color: #f4f4f4;
}
.requestheader h3 {
    font-weight: 700;
    font-size: 32px;
    line-height: 38.4px;
    margin-bottom: 16px;
}
.requestheader p {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
}
.contmargin {
    position: relative;
    margin-inline: 24px;
    padding-block: 150px 108px;
}
.explore a {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #0065ff;
}

@media (min-width: 1366px) {
    .container {
      width: 1300px;
      margin: auto;
      position: relative;
      padding-block: 50px 0px;
    }

}