.not_found{
    padding: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.not_main{
    display: flex;
    flex-direction: column;
    gap: 0.7rem
    ;
    align-items: center;
}

.not_found .not_main h1{
font-size: 24px;
font-weight: 800;
line-height: 28.8px;
letter-spacing: -0.01em;
text-align: center;
}
.not_main img{
    width: 70%;
}

.not_main a{
    width: fit-content !important;
    padding-left: 2rem !important;
    padding-right: 2rem !important;
}

@media screen and (max-width: 550px) {
    .not_found{
        padding: 5rem 5%;
    }
    .not_main h1{
        font-size: 1.1rem !important;
    }

    .not_main p{
        font-size: 0.8rem !important;
    }

    .not_main img{
        width: 90%;
    }
}