/* THIS IS USED TO STYLE MAJOR FEATURES  */
.majorfeaturecont {
  padding-bottom: 0 !important;
}
.versionheader h3 {
  font-weight: 500;
  line-height: normal;
  font-size: 32px;
  color: #0065ff;
  padding-bottom: 1rem;
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}
.versionheader p {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 20px;
}
.versioncontent {
  max-width: 800px;
}
.majorfeature,
.otherfeature {
  font-weight: 700;
  font-size: 24px;
  line-height: 28.8px;
  color: #0065ff;
  padding-block: 23px;
  padding-left: 55px;
  background-color: #e6f0ff;
  border-radius: 4px;
  margin-block: 34px 32px;
  margin-inline: 24px;
}
.majorfeaturecontent h4,
.otherfeaturecontent h4 {
  font-weight: 500;
  font-size: 24px;
  line-height: 33.6px;
  margin-block: 16px;
}
.majorfeaturecontent h5,
.otherftsubtitle h5,
.otherfeaturecontent h5 {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  margin-block: 24px 16px;
}


@media (max-width: 550px) {
  .versionheader {
    display: flex;
    flex-direction: column-reverse;
  }
  .versionheader p {
    margin-bottom: 0.7rem;
  }
  .majorfeature,
  .otherfeature {
    margin-block: 49px 32px;
    border-radius: 0px;
    padding-left: 0px;
    text-align: center;
    margin-inline: 0px;
  }
  .majorfeaturecontent h4,
  .otherfeaturecontent h4 {
    font-size: 20px;
    line-height: 28px;
  }
}
@media (min-width: 1366px) {
  .container {
    width: 1300px;
    margin: auto;
  }
}

@media (max-width: 1366px) {
  .container {
    width: 100% !important;
  }
}
