.featureinfo {
    max-width: 490px;
}
.featureinfo h4 {
    font-weight: 500;
    font-size: 24px;
    line-height: 33.6px;
}
.featureinfo p {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
}
.featureinfo * {
    margin-bottom: 16px;
}
.featureitem {
    margin-top: 138px;
    margin-bottom: 102px;
}
.featureitemsbcg {
    background: #E6F0FF;
}
.featureimage {
    max-width: 554px;
}
.contmargin {
    position: relative;
    margin-inline: 24px;
    padding-block: 78px 102px;
}
.featureasset {
    max-width: 600px;
    position: relative;
}

.featureasset video{
    border-radius: 16px;
    width: 100%;
}

/* .featurevideo {
    max-width: 600px;
} */

.featureasset ::-webkit-media-controls {
    display: none;
  }


@media (max-width: 430px) {
    .contmargin {
        padding-block: 56px;
    } 
}
@media (max-width: 678px) {
    .featureasset img {
        width: 100% !important;
    }
}
@media (min-width: 1366px) {
    .container {
      width: 1300px;
      margin: auto;
    }
}