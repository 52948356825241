.background {
    background: linear-gradient(45deg, rgba(34, 122, 255, 0.5), rgba(255, 255, 255, 0.22));
}
.featurescont p{
    font-size: 1rem !important;
}
.aboutheader {
    text-align: center;
    max-width: 607px;
    margin: auto;
    padding-bottom: 543px;
}
.aboutheader h2,
.staffstdn h2 {
    font-weight: 500;
    line-height: 45px;
    font-size: 38px;
    margin-block: 0px 16px;
}
.aboutheader p {
    font-weight: 500;
    line-height: 29px;
    font-size: 16px;
    margin-top: 8px;
}
.contmargin {
    position: relative;
    margin-inline: 24px;
    padding-block: 75px;
}
.shelfimg {
    top: 252px;
    position: absolute;
}
.features {
    max-width: 930px;
    margin-inline: auto;
    text-align: center;
}
.features h3{
    font-size: 30px;
    font-weight: 700;
    line-height: 30px;
}
.features p,
.staffstdn p{
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
}
.features p:first-of-type {
    margin-top: 16px;
}
.bckgroundblack {
    background-color: #222222;
    color: #FFF;
}
.staffstdn {
    max-width: 962px;
}
.videocont {
    margin-block: 54px;
    margin-inline: 24px;
    padding-block: 45px 0px;
    background: #E3EFFC;
    border-radius: 19.89px;
}
.forvideo {
    margin-top: 37px;
    max-width: 1152.29px;
    height: 700px;
}
.videocont h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #001633;
}

.integrate{
    padding: 3rem 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
}

.integrate button{
    background: #FFFFFF;
    border-radius: 16px;
    border: 1px solid #0065FF;
    font-size: 16px;
    cursor: pointer;
    padding: 0.8rem 1.5rem;
    color: #0065FF;
    font-weight: 600;
    text-align: center;
    margin-top: 0.8rem;
}

.integrate_div{
    padding-top: 3rem;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    align-items: center;
    gap:  2rem;
    place-items: center;
    
}

@media screen and (max-width: 1120px) {
    .integrate_div{
        display: flex;
align-items: center;
flex-wrap: wrap;
        justify-content: center;
        place-items: center;
    }
}

@media screen and (max-width: 390px) {
    .featurescont {
        margin-top: 60px !important;
    }
    .contmargin {
        padding-block: 15px;
    }
    p {
        line-height: 22px !important;
    }
    .shelfimg{
        margin-top: 1rem !important;
    }
}
@media screen and (min-width: 390px) and (max-width: 430px) {
    .featurescont  {
        margin-top: 120px !important;
    }
    .shelfimg{
        margin-top: 2rem !important;
    }
}
@media screen and (max-width: 550px) {
    .videocont h3 {
        line-height: 28px;
        margin-inline: 53px;
    }

    .videocont {
        margin-inline: 0px;
        border-radius: 0px;
        padding-block: 45px 77px;
    }
    .forvideo {
        height: 236px;
        padding-inline: 34px 34.47px;
    }
    .contmargin {
        padding-block: 40px;
    }

    .shelfimg {
        margin-top: 4rem;
        top: 150px;
    }
    .features {
        margin-inline: 24px;
    }
    .integrate_div{
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;
    }
    .integrate_div img{
        transform: scale(70%);
    }
}

@media screen and (min-width: 824px) and (max-width: 949px) {
    .aboutheader {
        padding-bottom: 330px !important;
    }
    .featurescont {
        margin-top: 180px !important;
    }
}
@media screen and (min-width: 950px) and (max-width: 1034px) {
    .featurescont {
        margin-top: 150px !important;
    }
    .aboutheader {
        padding-bottom: 400px !important;
    }
}
@media screen and (min-width: 1062px) and (max-width: 1189px) {
    .featurescont {
        margin-top: 160px !important;
        margin-inline: auto;
    }
}
@media screen and (min-width: 1189px) and (max-width: 1248px) {
    .featurescont {
        margin-top: 250px !important;
        margin-inline: auto;
    }
}
@media screen and (min-width: 1249px) and (max-width: 1365px) {
    .featurescont {
        margin-top: 310px !important;
        margin-inline: auto;
    }
}
@media (max-width: 813px) {
    .aboutheader {
        padding-bottom: 231px;
    }
    .aboutheader h2{
        font-size: 2rem;
        line-height: normal;
    }
    
    .bckgroundblack h2{
        font-size: 2.2rem !important;
    }
   
    .bckgroundblack{
        padding: 2rem 5% !important;
    }
}

@media (max-width: 1365px) {
    .featurescont {
        margin-top: 170px;
        margin-inline: auto;
    }
}
@media (min-width: 1366px) {
    .container,
    .featureitem {
      width: 1200px;
      margin: auto;
    }
    .featurescont {
        margin-top: 245px;
        width: 1200px;
        margin-inline: auto;
    }
}

@media screen and (max-width: 430px) {
    .staffstdn h2{
line-height: normal !important;
font-size: 1.5rem !important;    }
}