.contmargin {
    margin-inline: 24px;
    padding-block: 54px;
}
.faqheader h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 44.8px;
    text-align: center;
    margin-bottom: 30px;
}
.questionmark {
    background-color: #E6F0FF;
    padding:  9px;
    color: #0065FF;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contmargin h4{
    margin-top: 10px;
    margin-inline: 16px;
    font-size: 16px;
    line-height: 20.8px;
    font-weight: 400;
}
.answers {
    font-weight: 400;
    font-size: 16px;
    margin-inline: 72px;
}
/* .css-1086bdv-MuiPaper-root-MuiAccordion-root::before {
    border: 1px solid #F4F4F4 !important;
} */
@media (max-width: 430px) {
    .contmargin {
        padding-block: 20px;
    }
    .questionmark {
        display: none;
    }
    .question {
        margin-inline: 0px;
    }
    .answers {
        margin-inline: 0px;
    }
    .contmargin h4 {
        font-size: 16px;
        margin-inline: 0px;
        font-weight: 500;
    }
}
@media (min-width: 1366px) {
    .container {
      width: 1300px;
      margin: auto;
    }
}