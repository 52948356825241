.norequestmodal {
    max-width: 519px;
    margin-block: 99px;
    margin: auto;
    text-align: center;
}
.norequestmodal h4 {
    font-weight: 700;
    font-size: 24px;
    line-height: 28.8px;
    margin-bottom: 16px;
}
.errdescription {
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 16px;
}
.errimg {
    filter: grayscale(100%);
}
