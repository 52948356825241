.herocontainer{
    background-color: #04326B;
    padding: 142.67px 108px 60px 108px;
}
.heroimage{
    width: 600px;
    height: 450px;
    
}

.videowrapper ::-webkit-media-controls {
    display: none;
  }
.contmargin{
    margin-inline: 24px;
    position: relative;
    z-index: 97px;
}
.herofirst{
    max-width: 445px;
    background: #E6F0FF;
    padding: 12px 24px;
    font-weight: 500;
    border-radius: 88px;
    margin-bottom: 24px;
}
.heroflexleft {
    max-width: 600px;
    flex: 1;
}
.smscreen{
    display: none;
}
.herocontainer h1{
    font-weight: 400;
    font-size: 43px;
    line-height: 57.8px;
    color: #FFF;
}
.herocontainer h6 {
    font-size: 24px;
    color: #FFF;
    line-height: 33.6px;
    font-weight: 400;
    margin-block: 24px;
}
.ringone{
    position: absolute;
    top: -70px;
    left: -40px;
    z-index: 1;
    opacity: 0.05;
}
.ringtwo {
    position: absolute;
    top: 260px;
    left: -40px;
    z-index: 1;
    opacity: 0.05;
}
@media screen and (max-width: 1100px) {
    .herocontainer{
        padding-top: 10rem !important;
        padding: 5rem 7%;
    }
}
@media screen and (max-width: 985px) {
    .herocontainer{
        padding-top: 5rem !important;
        padding: 5rem 5%;
    }
}

@media (max-width: 550px) {
    .herofirst {
        display: none;
    }
    .lgscreen{
        display: none;
    }
    .smscreen {
        display: block;
        
    }
    .herocontainer h1{
        text-align: center;
        font-weight: 500;
        line-height: 31.2px;
        font-size: 24px;
    }
    .herocontainer h6{
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
        text-align: center;
        margin-block: 16px;
    }
    .contmargin {
        margin-inline: 16px;
    }
}

@media (max-width: 326px) {
    .ringone,
    .ringtwo {
        display: none;
    }
}
@media (max-width: 566px) {
    .herocontainer {
        background-color: #000;
    }
    .container {
        background-color: #000;
    }
    .herofirst {
        color: #000;
    }
    .logo {
        color: #000000;
    }
}
@media (max-width: 1132px) {
    .heroimage {
        display: none;
    }
}
@media (min-width: 1366px) {
    .container {
      width: 1300px;
      margin: auto;
      position: relative;
      padding-block: 50px 0px;
    }

}
