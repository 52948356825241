.containerbcg {
    background-color: #f4f4f4;
}
.contmargin {
    position: relative;
    margin-inline: 24px;
    padding-block: 150px 108px;
}

.requestheader h3 {
    font-weight: 700;
    font-size: 32px;
    line-height: 38.4px;
    margin-bottom: 16px;
}
.requestheader p {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
}

.formarea {
    margin-top: 35px;
    background: #FFF;
    padding: 48px 48px 72px 48px;
    border-radius: 16px;
}
.formleftcont {
    width: 50%;
}
.featureimg {
    width: 50%;
}

@media (min-width: 1366px) {
    .container {
      width: 1300px;
      margin: auto;
    }
}